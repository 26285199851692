@import url('https://fonts.googleapis.com/css2?family=Pragati+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
body {
    color: #292929;
    font-family: "Raleway", sans-serif;
}
:is(h1, h2, h3, h4, h5, h6) {
    font-family: "Pragati Narrow", sans-serif !important;
}
.font-bold{
    font-weight: bold;
}
.row {
    width: 100% !important;
    margin-left: 0px !important;
}
.contantBtn {
    background-color: #262873;
    border-color: #262873;
    font-size: 15px;
    border-radius: 5px;
    color: white;
    &:hover {color: white !important;}
}
button:focus{box-shadow: none !important;}
.text-primary {color: #262873 !important;}
.text-dark {color: #000 !important;}
.font-75{font-size: 75px !important;}
.font-60{font-size: 60px !important;}
.font-50{font-size: 50px !important;}
.font-25{font-size: 25px !important;}
.font-18 {font-size: 18px !important;}
.font-14{font-size: 14px !important;}
.font-12 {font-size: 12px !important;}
.arrow {
    position: absolute;
    top: 0;
    &.left {left: -20px;}
}
.desp {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limits text to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; /* Adjust line-height as per design */
    height: 4.5em; /* 3 lines of text */
    &.five-liner {
        -webkit-line-clamp: 5; /* Limits text to 3 lines */
        height: 135px;
    }
}
.gradeBtn {
    background: white;
    border: 1px solid #262873;
    border-bottom-width: 5.5px;
    border-radius: 10px;
    padding: 15px 25px;
    margin-right: 20px;
    margin-top: 33px;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-size: 25px;
    font-family: ui-monospace;
    transition: all 0.3s ease;
    &:hover{
        background: #262873;
        color: white;
    }
}
.note-card {
    background: #26287321;
    border: 1px solid #5255B0;
    border-radius: 10px;
}
.find-here-card {
    border-radius: 10px;
    box-shadow: 0 0 15.5px 0 rgba(0,0,0,0.16);
}
.program-card {
    border-radius: 30px;
    padding: 10px 20px 20px;
    text-align: center;
    margin-top: 75px;
    img {
        height: 90px;
        margin-top: -45px;
    }
    &.early{
        background: #DDD7FF;
        .points {color: #6152AD;}
    }
    &.Intermediate{
        background: #CBEAFF;
        .points {color: #2C6084;}
    }
    &.Advanced{
        background: #FFDCE8;
        .points {color: #8B3955;}
    }
    &.Summer{
        background: #BFFFCD;
        .points {color: #248638;}
    }
    &.Homework{
        background: #FFEEC7DE;
        .points {color: #806228;}
    }
}
.for-mobile-only{display: none;}
.form-control,.form-select {
    height: 50px;
}

.aboutusBgCont {
    height: 80vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;

    .aboutusTxtCont {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        height: 100%;
        width: 100%;
        background: linear-gradient(to right bottom, #2b314c88, #313958a2, #313958da);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .txt {
            width: 55%;
        }
    }
}
.about-us-card {
    box-shadow: 0 0 15.5px 0 rgba(0,0,0,0.10);
    border-radius: 8px;
}
.icon {
    min-width: 25px;
}
@media (max-width: 576px) {  
    .font-75 {font-size: 40px !important;}
    .font-60 {font-size: 30px !important;}
    .font-50 {font-size: 25px !important;}
    .font-25 {font-size: 18px !important;}
    .home-banner-cont {
        .col-md-6.ps-0.pe-5 {
            padding: 0px 15px !important;
            >h1 {margin-top: 10px !important;}
        }
    }
    .pb-5 {padding-bottom: 20px !important;}
    .mt-5 {margin-top: 20px !important;}
    .arrow {display: none;}
    .home-main-cont {
        .home-banner-cont {
            .row.justify-content-center.pb-5 {
                padding: 0px 15px 20px !important;
            }
            h1 {margin-top: 10px !important;}
        }
        p.mb-2.mt-4.font-18.px-5 {
            margin-top: 10px !important;
            padding: 0px !important;
        }
        .col-md-11.px-4 {
            padding: 0px !important;
        }
        .program-sect-cont {
            background-position: right center !important;
        }
        .program-card {
            margin-top: 50px;
        }
    }
    .for-mobile-only{display: block;}
    .footer {
        text-align: center;
        .border-top {border-top: unset !important;}
        a.d-flex{justify-content: center;}
    }
    .programs-cont {
        overflow-x: scroll;
        white-space: nowrap;
        flex-wrap: unset;
        padding-bottom: 20px;
        .find-here-card {
            .col-md-8 {padding: 10px 15px 0px !important;}
            white-space: normal;
            img {display: none;}
            .desp {
                height: 110px;
                font-size: 14px !important;
            }
        }
    }
    .gradeBtn {
        margin: 10px 10px 0px;
        font-size: 18px;
        padding: 10px 20px;
    }
    .about-page {
        .aboutusBgCont {
            height: 60vh;
            .txt {
                padding: 0px 10px;
                width: 100% !important;
            }
        }
        .col-md-6.ps-5 {
            padding-left: 15px !important;
            text-align: center;
            padding-top: 25px;
        }
        .font-18 {font-size: 16px !important;}
        .row.justify-content-center.mt-5.pt-3 {
            padding-top: 0px !important;
        }
        .approach-sect-cont {
            overflow-x: scroll;
            white-space: nowrap;
            flex-wrap: unset;
            margin: 0px !important;
            padding: 20px 0px;
            .about-us-card {
                white-space: normal;
                .text-muted {font-size: 14px;}
            }
        }
        .row.justify-content-around {
            .font-18.mb-5 {
                text-align: center;
            }
            a{font-size: 14px;}
            iframe {
                height: 300px !important;
                margin-top: 30px;
            }
        }
        
    }
    ::-webkit-scrollbar { display: none;}
}

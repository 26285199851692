.navbar {
    padding: 0px 35px;
    background-color: white;
  }

  .main {
    .nav-link {
      color: #443939 !important;
      &:hover {
        color: #443939;
      }
      &.active {
        font-weight: bold;
        color: #262873 !important;
        border-bottom: 2px solid #262873;
      }
    }
  }
  
@media (max-width: 576px) { 
  .navbar {padding: 0px 15px;
    .navbar-brand {margin: 0px !important;}
    .nav-item {
      width: fit-content;
      margin: 10px auto;
      &.pointer.px-3.ms-5 {
        margin-left: auto !important;
        margin-bottom: 20px;
      }
    }
    .navbar-toggler-icon {filter: brightness(0);}
  }
}
